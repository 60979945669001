.footer {
  border-top: 1px solid rgba(#000000, 0.25);

  .pagination-col,
  .fullscreen-col {
    display: flex;
  }

  .pagination-col {
    justify-content: center;
  }

  .fullscreen-col {
    justify-content: flex-end;
  }
}
