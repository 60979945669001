.modal{
  display: inline-block;
  .modal-link{
    font-size: 32px !important;
    margin: 10px !important;
    line-height: 1 !important;
    cursor: pointer;
  }

}
