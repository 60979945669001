.workspace-header {
  .right-col {
    display: flex;
    justify-content: flex-end;

    .avatar {
      margin-right: 10px;
    }

    .profile-link {
      vertical-align: middle;

      margin-right: 20px;
    }

    .exit-icon {
      font-size: 26px;

      vertical-align:middle;
    }
  }
}
