.avatar{
  position: relative;
  line-height: 2.2;
  width:100%;
  height: 100%;

  .info{
    display: block;
    height: 100%;
    font-size: 16px;
  }
  .caption{
    display: block;
    font-size: 8px;
  }
}
