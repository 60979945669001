.sign-in {
  display: flex;

  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  .content {}
}
