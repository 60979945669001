.header {
  background-color: #f0f2f5 !important;

  border-bottom: 1px solid rgba(#000000, 0.25);

  .logo{
    position: relative;
    margin:0;
    padding: 4px;
    width: 12em;
    height: 4.0em;
  }
}
