@import '~normalize.css';
@import '~antd/dist/antd.css';

html {
  min-width: 960px;
}

#root {
  width: 100%;
  height: 100%;
}

:focus {
  outline: none;
}
