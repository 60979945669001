.content-subjects{
  display: flex;
  flex-direction: column;
  width: 100;
  height: 100%;
  .content-header{
    flex: 0;
    .content-links{
      flex:1;
      margin-top: 20px;
      padding-left: 5px;
      .link{
        color: #f5222d;

        padding: 5px 0 5px 30px !important;
      }
    }
  }
  .content-lessons{
    flex: 1;
    margin-top: 30px;
  }

}
