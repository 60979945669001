.presentation {
  width: 100%;
  height: 100%;
  position: absolute;
  margin: 0;
  top: 0;
  bottom:0;
  left:0;
  right: 0;

  .header {}

  .content {
    display: flex;

    flex: 1;

    align-items: center;
    justify-content: center;

    padding: 50px;

    .presentation {}
  }

  .footer {}
}
