.side-header{
  flex: 0.5;
  .header{
    display: flex;
    position: relative;
    align-items: flex-end;

    .left{
      justify-content: flex-start;
    }
    .edit{
      margin: 0 0 20px 10px;
      cursor: pointer;
      color: #40a9ff;
      justify-items: flex-end;
    }
  }
}
