.workspace {
  display: flex;
  position:relative;
  flex-direction: column;
  margin: 0;
  width: 100%;
  height: 100%;

  .content {
    display: flex;
    flex: 1 1;
  }

  .footer {}
}
