
.dynamic-delete-button {
  cursor: pointer;

  font-size: 20px;
  color: rgb(160, 53, 53);
  transition: all .3s;
}
.dynamic-delete-button:hover {
  color: #999;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.dynamic-check-button {
  cursor: pointer;
  margin-left: 10px;
  font-size: 20px;
  color: rgb(45, 179, 100);
  transition: all .3s;
}
.dynamic-check-button:hover {
  color: #777;
}
.dynamic-check-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.edit-input{
  width: 66% !important;
  margin: 0 8px 0 8px !important;
}
