.auth-form {
  border: 1px solid rgb(185, 181, 181);
  border-radius: 8px;

  .content {
    display: flex;

    flex-direction: column;

    justify-content: center;

    width: 350px;

    padding: 24px;

    .header,
    .alert {
      margin-bottom: 24px;
    }

    .header {
      font-size: 28px;
    }

    .alert {
      text-align: center;
    }

    .form-item {
      width: 100%;
    }

    .form-button {
      margin-top: 10px;
    }
  }
}
