@charset "UTF-8";
.lessons {
  margin: 0 15px 0 0 !important; }
  .lessons .lesson-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }
    .lessons .lesson-header .progress {
      background: null; }
    .lessons .lesson-header .progress-success {
      background: #51cc51; }
    .lessons .lesson-header .progress-warning {
      background: #d3d147; }
    .lessons .lesson-header .header {
      line-height: 2;
      vertical-align: middle; }
    .lessons .lesson-header .time {
      line-height: 3;
      margin-right: 10px; }
  .lessons .lesson-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%; }
    .lessons .lesson-content .content-links {
      flex: 1; }
      .lessons .lesson-content .content-links .links-block {
        margin-bottom: 15px; }
      .lessons .lesson-content .content-links .link {
        color: #f5222d;
        padding: 5px 0 5px 30px !important; }
      .lessons .lesson-content .content-links .list {
        position: relative;
        list-style: none;
        margin-left: 0;
        padding-left: 1.2em; }
      .lessons .lesson-content .content-links .list-item:before {
        content: "–";
        margin-right: 1rem; }
    .lessons .lesson-content .content-play {
      flex: 1;
      text-align: right;
      display: flex;
      flex-direction: column;
      align-items: flex-end; }
      .lessons .lesson-content .content-play .play-background {
        border: 1px solid transparent;
        border-radius: 0.4rem;
        padding: 0 10px 0 0;
        background: #51cc5142;
        transition: 0.5s; }
      .lessons .lesson-content .content-play .play-link {
        text-decoration: none; }
        .lessons .lesson-content .content-play .play-link .play-btn {
          background: #51cc51;
          vertical-align: middle; }
        .lessons .lesson-content .content-play .play-link .text {
          margin-left: 10px;
          display: inline-block;
          vertical-align: -10%;
          transition: 0.2s; }
      .lessons .lesson-content .content-play .play-background:hover {
        background: #6cd66c; }
        .lessons .lesson-content .content-play .play-background:hover .text {
          color: white; }
      .lessons .lesson-content .content-play .reset-background {
        text-align: right;
        border: 0;
        padding: 10px 10px 0 0;
        background: transparent;
        transition: 0.5s;
        cursor: pointer; }
      .lessons .lesson-content .content-play .reset-link {
        text-decoration: none; }
        .lessons .lesson-content .content-play .reset-link .reset-btn {
          background: #f5222d;
          vertical-align: middle;
          -webkit-transition: all 0.3s infinite ease;
          -moz-transition: all 0.3s infinite ease;
          -o-transition: all 0.3s infinite  ease;
          transition: all 0.3s infinite  ease; }
        .lessons .lesson-content .content-play .reset-link .text {
          margin-left: 10px;
          display: inline-block;
          vertical-align: -10%;
          transition: 0.2s; }
      .lessons .lesson-content .content-play .reset-background:hover .reset-btn {
        animation: spin 2s linear infinite;
        -webkit-animation: spin 2s linear infinite; }
      .lessons .lesson-content .content-play .reset-background:hover .text {
        color: #e95858; }

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg); } }
