.sider-subjects {
  flex: 1;

  .subjects {
    .subject {
      display: flex;

      align-items: center;

      margin: 20px 10px 0 10px;
      padding: 15px;

      border: 3px solid transparent;
      border-radius: 10px;

      text-decoration: none;

      &.active {
        border-color: red;
      }

      .logo {
        margin-right: 15px;
      }

      .content {
        .title {
          margin: 0;

          color: rgba(#000000, 0.85);

          font-size: 22px;
          font-weight: 500;
        }

        .description {
          display: flex;

          .item {
            margin-top: 10px;
            margin-right: 5px;
          }
        }
      }
    }
  }
}
