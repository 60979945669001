.content-form{
  .side{
    background: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    .sider-header{

    }
  }
  .content{
    padding: 10px
  }
}
